
import { defineComponent, inject, ref, computed, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Mixins from '@/mixins/mixins'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import Slider from '@vueform/slider'
import Download from '@/components/dialog/DownloadJson.vue'
import DialogAdd from '@/components/dialog/WatchlistAdd.vue'
import DialogProjectShare from '@/components/dialog/ProjectShare.vue'

export default defineComponent({
  name: 'Result',
  components: {
    Slider,
    download: Download,
    'dialog-add': DialogAdd,
    'dialog-share': DialogProjectShare,
  },
  setup() {
    // Vue3のCompositAPIでの取得方法
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const mixins = new Mixins()
    const conf: any = inject('$conf')
    const queries = mixins.getQuery()

    // 設定値扱い
    const scale = [
      {value: 1, label: '1秒間隔'},
      {value: 5, label: '5秒間隔'},
      {value: 10, label: '10秒間隔'},
      {value: 30, label: '30秒間隔'},
      {value: 60, label: '60秒間隔'},
    ]
    const lazyOptions = {
      src: 'your image url',
      lifecycle: {
        loading: () => {
          console.log('image loading')
        },
        error: () => {
          console.log('image error')
        },
        loaded: () => {
          console.log('image loaded')
        },
      },
    }

    // 読み込み時に生成する非リアクティブな値たち
    const sources: any = []

    let result: any = []
    if (queries.sourceId) {
      result = {
        source_id: queries.sourceId,
      }
    } else {
      router.push('../')
    }

    // 権限をセット
    const isPowerUser = store.getters.isPowerUser
    const isSuperUser = store.getters.isSuperUser

    return {
      route,
      router,
      store,
      mixins,
      conf,
      scale,
      lazyOptions,
      sources,
      result,
      isPowerUser,
      isSuperUser,
    }
  },
  data(): {
    project: any
    isScale: number
    isUpdate: boolean
    timeline: any[]
    filterdTimeline: any[]
    sceneQuery: any
    ui: any
    composing: boolean
    error: string | null
    player: any
    options: any
    isPlaying: any | null
    preview: any
    face: any
    speech: any
    hasonly: boolean
    context: any
    share: any
    filterdScenes: any
    ginfos: any
    actions: any
    emotions: any
    download: any
    dialog: any
    setsmile: any
    marker: any
  } {
    return {
      project: null,
      isScale: 5,
      isUpdate: false,
      timeline: [],
      filterdTimeline: [],
      sceneQuery: {
        cast: {
          open: true,
          score: [0, 100],
          value: [],
          same: false,
          suggest: [],
          loiter: false,
          gwatch: true,
          pwatch: true,
        },
        action: {
          open: true,
          score: [50, 100],
          value: 0,
          options: [
            {value: 0, label: '指定なし'},
            {value: 1, label: 'うなずき'},
          ],
          getactions: [null, false],
          disabled: false,
        },
        emotion: {
          open: true,
          smile: [0, 5],
          score: [70, 100],
          value: 0,
          options: [
            {value: 0, label: '指定なし'},
            {value: 6, label: '幸せ'},
            {value: 7, label: '悲しみ'},
            {value: 1, label: '怒り'},
            {value: 8, label: '驚き'},
            {value: 3, label: '困惑'},
            {value: 4, label: 'うんざり'},
            {value: 5, label: '恐れ'},
            {value: 2, label: 'おだやか'},
          ],
          getemotions: [null, false, false, false, false, false, false, false, false],
        },
        speech: {
          open: true,
          value: null,
          disabled: false,
        },
        sozai: {
          open: true,
        },
      },
      ui: {
        tab: 1,
      },
      composing: false,
      error: null,
      player: null,
      options: {
        autoplay: false,
        controls: true,
        // sources: [
        //   // {
        //   //   src: 'https://kaotan2021-video-out.s3-ap-northeast-1.amazonaws.com/videos/befc6864-bedf-11eb-8993-0242ac120004_conv.mp4',
        //   //   type: 'video/mp4',
        //   // },
        // ],
      },
      isPlaying: null,
      preview: {
        open: true,
        source_id: null,
        video_url: null,
      },
      face: [],
      speech: [],
      hasonly: true,
      context: null,
      share: null,
      filterdScenes: [],
      ginfos: {},
      actions: {},
      emotions: {},
      download: null,
      dialog: {
        type: null,
        method: null,
        data: null,
      },
      setsmile: false,
      marker: true,
    }
  },
  computed: {
    // 選択されている人物を集約
    selectedGroups(): any {
      let selectedGroups: any = []
      // const source = this.sources[0]
      if (this.sceneQuery.cast.value.length) {
        this.sceneQuery.cast.value.forEach((selectedCast: any) => {
          // console.log(selectedGroup)
          Object.keys(this.ginfos).forEach((key: any) => {
            let group = this.ginfos[key].groupInfos.find((group: any) => group.groupId === selectedCast.groupId) // 単一素材の場合はgroupIdで比較
            if (group) {
              group = Object.assign({}, group)
              group.source_id = key
              group.source_name = this.ginfos[key].source_name
              group.video_start_time = this.ginfos[key].start_time // 動画の開始時間を入れる
              selectedGroups.push(group)
            }
          })
        })
      }
      return selectedGroups
    },
    // 選択されている人物の登場シーンを集約
    selectedScenes(): any {
      let selectedScenes: any = []
      this.selectedGroups.forEach((group: any) => {
        if (this.preview.source_id !== group.source_id) { return }
        const vst = new Date(group.video_start_time).getTime() // 動画開始時間のエポックミリ秒
        group.sceneInfo.forEach((scene: any) => {
          // ひとまず各シーンの代表顔のデータを基とする
          selectedScenes.push({
            source_id: group.source_id,
            source_name: group.source_name,
            name: group.name,
            pwatch_name: group.pwatch_name,
            startTime: (new Date(scene.startTime).getTime() - vst) / 1000,
            endTime: (new Date(scene.endTime).getTime() - vst) / 1000,
            xPos: scene.representativeFace.xPos,
            yPos: scene.representativeFace.yPos,
            width: scene.representativeFace.width,
            height: scene.representativeFace.height,
            frontalFaceScore: scene.representativeFace.frontalFaceScore,
            score: scene.representativeFace.score,
          })
        })
      })
      // 開始順で並べる
      selectedScenes.sort((a: any, b: any) => {
        return a['startTime'] - b['startTime']
      })
      return selectedScenes
    },
    allcheck(): any {
      return this.filterdTimeline.every((box: any) => box.select)
    },
    action(): any {
      if (this.sceneQuery.action.value) {
        const keys = ['', 'nod']
        const min = this.sceneQuery.action.score[0]
        const max = this.sceneQuery.action.score[1]
        const data = this.actions[this.sources[0].source_id][keys[this.sceneQuery.action.value]]?.filter((item: any) => {
          return min <= item.confidence * 100 && item.confidence * 100 <= max
        })
        if (data) {
          return data
        }
      }
      return null
    },
    emotion(): any {
      if (this.sceneQuery.emotion.value) {
        const keys = [
          '',
          'angry',
          'calm',
          'confused',
          'disgusted',
          'fear',
          'happy',
          'sad',
          'surprised',
          'smile',
        ]
        const min = this.sceneQuery.emotion.score[0]
        const max = this.sceneQuery.emotion.score[1]
        const data = this.emotions[this.sources[0].source_id][keys[this.sceneQuery.emotion.value]].filter((item: any) => {
          return min <= item.Confidence && item.Confidence <= max
        })
        if (data) {
          return data
        }
      }
      return null
    },
  },
  watch: {
    filterdTimeline() {
      // console.log('filterd done')
      // this.isUpdate = false
      // this.store.commit('clearPending', false)
      setTimeout(() => {
        // console.log('filterd done')
        this.isUpdate = false
        this.store.commit('clearPending', false)
      }, 1000)
    },
    'sceneQuery.speech.value': function (val) {
      val = val.replace(/^[\u{20}\u{3000}]+|[\u{20}\u{3000}]+$/gu, '')
      if (val) {
        const queries = val.split(/[\u{20}\u{3000}]/u)
        // const paragraph = box.speech_data.map((speech: any) => speech.word.split('|')[0]).join('')
        queries.forEach((q: any) => {
          const query = `^(?=.*${q}).*$`
          const reg = new RegExp(query, 'i')
          this.speech.forEach((s: any) => {
            if (s.text.match(reg)) {
              s.select = true
            }
          })
        })
      } else {
        this.speech.forEach((s: any) => {
          s.select = false
        })
      }
    },
  },
  async beforeMount() {
    this.project = this.store.getters.project
    if (!this.project) {
      this.project = await this.mixins.getProject({
        project_id: this.route.params.project_id,
      })
      // projectの取得、権限確認
      if (!this.project || this.project.user_type === null) {
        this.router.push('/')
      }
      this.store.commit('setProject', this.project)
    }
    await this.setData()
  },
  beforeUnmount() {
    this.isPlaying = null
    if (this.player) {
      this.player.dispose()
    }
  },
  methods: {
    async refreshProject() {
      console.log('refresh')
      this.project = await this.mixins.getProject({
        project_id: this.route.params.project_id,
      })
      // projectの取得、権限確認
      if (!this.project || this.project.user_type === null) {
        this.router.push('/')
      }
      this.store.commit('setProject', this.project)
    },
    async setData() {
      this.isUpdate = true
      this.store.commit('setPending', true) // filterdTimelineが更新されたらfalseにする
      // 入力データ
      const data = {
        inputs: [
          {
            source_id: this.result.source_id,
          },
        ],
      }
      // 対象の詳細を取得
      const source = await this.mixins.api('/api/getsource', data, null).then((res: any) => {
        return res.outputs[0]
      })
      source.visibility = true
      this.result = source

      document.title = `${(this.result.source_name || this.result.gdr_name)}｜KAOTAN`

      // 詳細からさらに各jsonを取得
      console.groupCollapsed('get analyze results')
      const jsons: boolean = await Promise.all([
        // 1Nが入った際に調整予定
        /*
        // 顔認識結果
        this.axios
          .get(source.face_url)
          .then((r: any) => {
            console.log('face_loiter:', r.data)
            source.face_data = r.data
          })
          .catch(() => {
            console.log('face_loiter error')
            source.face_data = null
          }),
        // 顔認識1N
        this.axios
          .get(source.face_1n_url)
          .then((r: any) => {
            console.log('face_1n:', r.data)
            source.face_1n_data = r.data
          })
          .catch(() => {
            console.log('face_1n error')
            source.face_1n_data = null
          }),
        // 表情
        this.axios
          .get(source.rekognition_url)
          .then((r: any) => {
            console.log('rekoginition:', r.data)
            source.rekog_data = r.data
          })
          .catch(() => {
            console.log('rekognition error')
            source.rekog_data = null
          }),
        */
        // 登場人物一覧
        this.axios
          .get(source.ginfos_url)
          .then((r: any) => {
            console.log('ginfos:', r.data)
            source.ginfos_data = r.data
          })
          .catch(() => {
            console.log('ginfos error')
            source.ginfos_data = null
          }),
        // 表情（笑顔）
        // this.axios
        //   .get(source.emo_smile_url)
        //   .then((r: any) => {
        //     console.log('emo_smile:', r.data)
        //     source.emo_smile_data = r.data
        //   })
        //   .catch(() => {
        //     console.log('emo_smile error')
        //     source.emo_smile_data = null
        //   }),
        // うなずき
        // this.axios
        //   .get(source.nod_url)
        //   .then((r: any) => {
        //     console.log('nod:', r.data)
        //     source.nod_data = r.data
        //   })
        //   .catch(() => {
        //     console.log('nod error')
        //     source.nod_data = null
        //   }),
        // 音声
        this.axios
          .get(source.speech_to_text_url).then((r: any) => {
            console.log('speech:', r.data)
            source.speech_data = r.data
          })
          .catch(() => {
            console.log('speech error')
            source.speech_data = null
          }),
      ]).then(() => {
        if (
          // source.face_data &&
          // source.face_1n_data &&
          // source.rekog_data &&
          // source.nod_data &&
          source.ginfos_data &&
          source.speech_data
        ) {
          console.log('source done')
          return true
        } else {
          console.log('source has some error')
          return false
        }
      })
      console.log('get jsons', jsons)
      console.groupEnd()

      // if (jsons) {
      // 取得がうまくいったら各値をセットする
      // sourcesは参照するのみなので非リアクティブ
      this.sources = [source]
      // リアクティブな子たち
      this.preview.source_id = source.source_id
      this.preview.video_url = source.video_url
      // this.face = {
      //   loiter: source.face_data?.queryResultInfo.groupInfo.filter((group: any) => !group.personId) || [],
      //   gwatch: source.face_data?.queryResultInfo.groupInfo.filter((group: any) => group.personId) || [],
      //   pwatch: source.face_1n_data?.privateWatchlitstInfo || [],
      // }
      // this.action = {
      //   nod: source.nod_data?.resluts,
      // }
      // this.emotion = {
      //   smile: source.rekog_data,
      // }

      // ginfos_dataをtotal_timeで整列
      if (source.ginfos_data) {
        source.ginfos_data.queryResultInfo.groupInfo.sort((a: any, b: any) => {
          const tta = a.total_time || 0
          const ttb = b.total_time || 0
          return ttb - tta
        })
      }

      if (source.nodinfo !== 1) {
        this.sceneQuery.action.disabled = true
      }

      if (source.speechinfo === 1 && source.speech_data && source.speech_data.length) {
        // this.speech = source.speech_data
        this.speech = source.speech_data.map((speech: any) => {
          return {
            start: speech.start,
            end: speech.end,
            word: speech.word,
            text: speech.word.split('|')[0],
            ano: speech.word.split('|')[1],
            select: false,
          }
        })
      } else {
        this.sceneQuery.speech.disabled = true
      }

      // castの仕込み
      // 実はresultではいらないかも
      const casts: any = []
      this.sources.forEach((source: any) => {
        // 登場人物一覧から
        // 素材ごとにgroupInfoがあるはず
        if (source.ginfos_data) {
          // 登場人物の詳細置き場を作成
          this.ginfos[source.source_id] = {
            start_time: source.start_time,
            source_name: source.source_name || source.gdr_name,
            groupInfos: [],
          }
          // 登場人物一覧を作成
          source.ginfos_data.queryResultInfo.groupInfo.forEach((group: any) => {
            // searchはunknownは除外
            // resultは入れる
            // if (!group.name) return
            // 重複チェック
            // 名前とpersonIdを見る、unknown対策でgroupIdもみる
            let exist: any = casts.find((cast: any) => cast.name === group.name && cast.personId === group.personId && cast.groupId === group.groupId)
            // console.log(group.name || group.pwatch_name, group, exist)
            if (!exist) {
              // 必要な値を格納
              const cast: any = {
                type: 'loiter',
                groupId: group.groupId,
                personId: group.personId,
                name: group.name,
                watchlistComment: group.watchlistComment,
                watchlistName: group.watchlistName,
                pwatch_id: group.pwatch_id,
                pwatch_name: group.pwatch_name,
                pwatch_other_name: group.pwatch_other_name,
                sourceId: source.source_id,
                sourceName: source.source_name || source.gdr_name,
                getginfos: false,
                ginfos: {},
                total_time: group.total_time || 0,
              }
              cast.ginfos[source.source_id] = {
                ginfoUrl: group.ginfoUrl,
                data: null,
                emo_names: group.emo_names,
              }
              casts.push(cast)
            } else {
              // console.log('複数素材に登場', group.name)
              exist.ginfos[source.source_id] = {
                ginfoUrl: group.ginfoUrl,
                data: null,
                emo_names: group.emo_names,
              }
              // existのtotal_timeに合算
              if (group.total_time) {
                exist.total_time += group.total_time
              }
            }
          })
        }
        // 登場人物を出演時間でソート
        casts.sort((a: any, b: any) => {
          const tta = a.total_time || 0
          const ttb = b.total_time || 0
          return ttb - tta
        })
        this.sceneQuery.cast.suggest = casts

        // catstついでに
        this.actions[source.source_id] = {
          nod: [],
        }
        this.emotions[source.source_id] = {
          angry: [],
          calm: [],
          confused: [],
          disgusted: [],
          fear: [],
          happy: [],
          sad: [],
          smile: [],
          surprised: [],
        }
      })

      // クエリに人物名が含まれている場合
      if (this.route.query.cast) {
        // stringとして変数を宣言
        let castqueries: any = this.route.query.cast as string
        // スペースをトリムして配列化
        castqueries = castqueries
          .replace(/^[\u{20}\u{3000}]+|[\u{20}\u{3000}]+$/gu, '')
          .replace(/[\u{20}\u{3000}]+/gu, '\v') // 垂直タブに置換してsplit
          .split('\v')
        // console.log(castqueries)
        // クエリの文字列を含む登場人物を全員抜粋
        let expectcasts = casts.filter((cast: any) => {
          // 文字列が人物名とかすったらtrue
          // 別名もやる
          return castqueries.some((q: string) => {
            const query = `^(?=.*${q.replace(/\t/g, ' ')}).*$` // tabをスペースに置換して比較
            const reg = new RegExp(query, 'gi')
            if (cast.name.match(reg)?.length || cast.watchlistComment.match(reg)?.length || cast.pwatch_name?.match(reg)?.length || cast.pwatch_other_name?.match(reg)?.length) {
              const reg2 = new RegExp(`^(?=.*${cast.name || cast.watchlistComment || cast.pwatch_name || cast.pwatch_other_name}).*$`, 'gi')
              const ginfo = source.ginfos_data.queryResultInfo.groupInfo.find((ginfo: any) => {
                return ginfo.name.match(reg2)?.length ||
                  ginfo.watchlistComment.match(reg2)?.length ||
                  ginfo.pwatch_name?.match(reg2)?.length ||
                  ginfo.pwatch_other_name?.match(reg2)?.length ? true : false
              })
              ginfo.select = true
              return true
            } else {
              return false
            }
          })
        })

        // クエリで見つかった登場人物をaddCast()していく
        expectcasts.forEach(async (cast: any) => {
          await this.clickFaceItem(cast, 'query')
        })
      }

      // クエリに音声認識が含まれている場合
      if (this.route.query.speech) {
        let speechqueries: any = this.route.query.speech as string
        // スペースをトリムして配列化
        speechqueries = speechqueries
          .replace(/^[\u{20}\u{3000}]+|[\u{20}\u{3000}]+$/gu, '')
          .replace(/[\u{20}\u{3000}]+/gu, ' ')
        // console.log(speechqueries)
        this.sceneQuery.speech.value = speechqueries
      }

      this.face = {
        loiter: source.ginfos_data?.queryResultInfo.groupInfo.filter((group: any) => !group.personId && !group.pwatch_name) || [],
        gwatch: source.ginfos_data?.queryResultInfo.groupInfo.filter((group: any) => group.personId && !group.pwatch_name) || [],
        pwatch: source.ginfos_data?.queryResultInfo.groupInfo.filter((group: any) => group.pwatch_id || group.pwatch_name) || [],
      }

      // video.jsのsetup
      const elem = this.$refs.videoPlayer as HTMLVideoElement
      // const elem = document.querySelector('#video') as HTMLVideoElement
      this.isPlaying = true
      this.options.sources = [{
        src: this.preview.video_url,
        type: 'video/mp4',
      }]
      // console.log(elem, this.options.sources)
      this.player = videojs(elem, this.options, () => {
        // console.log('onPlayerReady', 'hoge')
        // const elem = this.$refs.videoPlayer as HTMLVideoElement
        // elem.currentTime = this.source.currentTime
      })
      this.player.on('enterpictureinpicture', () => {
        this.togglePinP()
      })
      this.player.on('leavepictureinpicture', () => {
        this.togglePinP()
      })

      await this.updateTimeline()
      this.setSmileData()
    },
    async setSmileData() {
      // 重いけど最初にしゅとくするしかない？

      // console.groupCollapsed('get smile results')
      const jsons: any = await Promise.all(
        this.sources.map(async (source: any) => {
          // source.visibility = true
          return Promise.all([
            // 表情（笑顔）
            this.axios
              .get(source.emo_smile_url)
              .then((r: any) => {
                // console.log('emo_smile:', r.data)
                this.emotions[source.source_id]['smile'] = r.data.map((smile: any) => {
                  if (!smile.Value) {
                    smile.Confidence = 100 - smile.Confidence
                  }
                  delete smile.Value
                  return smile
                })
              })
              .catch(() => {
                console.log('emo_smile error')
                // source.emo_smile_data = null
              }),
          ]).then(() => {
            if (this.emotions[source.source_id]['smile'].length) {
              console.log(source.source_id, 'source smile done')
              return true
            } else {
              console.log(source.source_id, 'source smile has some error')
              return false
            }
          })
        })
      ).then((res: any) => {
        console.log('sources smiles done')
        return res
      })
      console.log('get smile jsons', jsons)

      // 取得したJSONをそっとtimelineに反映させる
      this.sources.forEach((source: any) => {
        const unit = this.isScale
        const source_start_time = new Date(source.start_time).getTime()
        const key = 'smile'
        this.emotions[source.source_id][key].forEach((emo: any) => {
          const tl = this.timeline.filter((tl: any) => tl.source_id === source.source_id)
          const ts = new Date(emo.ts).getTime()
          const index = Math.floor(((ts - source_start_time) / 1000) / unit)
          tl[index]?.emotion_data[key].push(emo)
        })
      })

      // smileデータを取得できましたと
      this.setsmile = true

      // console.groupEnd()
    },
    async updateTimeline() {
      this.isUpdate = true
      this.store.commit('setPending', true) // filterdTimelineが更新されたらfalseにする
      let timeline: any = []
      this.sources.forEach((source: any) => {
        if (source.visibility) {
          // 変数定義
          let tl: any = []
          let videoStartTime: any
          // NECまたは吾郎さんの値はZ付き
          // 影響範囲が大きいので解析データとの紐付けはこのまま行い
          // getFormatedDateで日本時間にする
          if (source.face_data) {
            videoStartTime = new Date(source.face_data.queryParams.videoStartTime).getTime() // NeoEVAの返却値
          } else {
            videoStartTime = new Date(source.start_time).getTime() // 吾郎さんの値
          }
          // const startTime = new Date(source.start_time).getTime() // 吾郎さんの値
          // const videoStartTime = new Date(source.face_data.queryParams.videoStartTime).getTime() // NeoEVAの返却値
          const duration = source.seconds // this.mixins.hhmmss2sec(source.duration) // 秒

          // 指定秒数分の箱を作る
          const unit = this.isScale
          const boxCount = Math.ceil(duration / unit)
          for (let i = 0; i < boxCount; i++) {
            const tc = videoStartTime + i * (1000 * unit)
            const boxStartTime = videoStartTime + i * (1000 * unit)
            const boxEndTime = boxStartTime + 1000 * unit
            tl.push({
              source_id: source.source_id,
              tc: tc,
              video_start_time: videoStartTime,
              start_time: boxStartTime,
              end_time: boxEndTime,
              img_src: `${this.conf.IMGBASE}${source.thumbnail_url_base}${String(i * unit).padStart(7, '0')}.jpeg`,
              img_hd_src: `${this.conf.IMGBASE}${source.thumbnail_hd_url_base}${String(i * unit).padStart(7, '0')}.jpeg`,
              video_url: source.video_url,
              source_name: source.source_name,
              select: false,
              face_data: [],
              action_data: {
                nod: [],
              },
              emotion_data: {
                angry: [],
                calm: [],
                confused: [],
                disgusted: [],
                fear: [],
                happy: [],
                sad: [],
                smile: [],
                surprised: [],
              },
              speech_data: [],
            })
          }
          // 各JSONをひとなめして箱にいれていく

          // 顔認識
          // 各シーンにもたせるのはなし

          // うなずき解析結果
         if (this.actions[source.source_id].nod.length) {
            this.actions[source.source_id].nod.forEach((nod: any) => {
              if (!('seconds' in nod.time_offset)) {
                nod.time_offset.seconds = 0
              }
              const index = Math.floor(nod.time_offset.seconds / unit)
              tl[index]?.action_data.nod.push(nod)
            })
          }

          // 表情解析結果
          // 笑顔
          // setsmileがtrueならここで追加するようにし
          // filterdの方にも処理を追加
          if (this.setsmile) {
            const source_start_time = new Date(source.start_time).getTime()
            const key = 'smile'
            const emotion = this.emotions[source.source_id][key]
            if (emotion.length) {
              this.emotions[source.source_id][key].forEach((emo: any) => {
                const ts = new Date(emo.ts).getTime()
                const index = Math.floor(((ts - source_start_time) / 1000) / unit)
                tl[index]?.emotion_data[key].push(emo)
              })
            }
          }
          // その他表情
          if (this.sceneQuery.emotion.value) {
            const emotionkeys = [
              '',
              'angry',
              'calm',
              'confused',
              'disgusted',
              'fear',
              'happy',
              'sad',
              'surprised',
              'smile',
            ]
            const source_start_time = new Date(source.start_time).getTime()
            const key = emotionkeys[this.sceneQuery.emotion.value]
            const emotion = this.emotions[source.source_id][key]
            if (emotion.length) {
              emotion.forEach((emo: any) => {
                const ts = new Date(emo.ts).getTime()
                const index = Math.floor(((ts - source_start_time) / 1000) / unit)
                tl[index]?.emotion_data[key].push(emo)
              })
            }
          }

          // 音声解析結果
          if (source.speech_data && source.speech_data.length) {
            source.speech_data.forEach((speech: any) => {
              const index = Math.floor(speech.start / unit)
              tl[index]?.speech_data.push(speech)
            })
          }

          timeline = timeline.concat(tl)
        }
      })
      // 箱をソート
      // timecodeを昇順でsort
      // timeline.sort((a: any, b: any) => {
      //   return a['tc'] - b['tc']
      // })
      // source.timeline = timeline

      this.timeline = timeline
      this.filterd()
    },
    async filterd() {
      console.groupCollapsed('filtered')
      const st = Date.now()
      let timeline: any = JSON.parse(JSON.stringify(this.timeline)) // こちらの方が動作が軽い気がする

      if (
        this.sceneQuery.cast.value.length ||
        this.sceneQuery.emotion.smile[0] > 0 ||
        this.sceneQuery.emotion.smile[1] < 5 ||
        this.sceneQuery.emotion.value ||
        this.sceneQuery.action.value ||
        this.sceneQuery.speech.value
      ) {
        // 変数定義
        const emotionkeys = [
          '',
          'angry',
          'calm',
          'confused',
          'disgusted',
          'fear',
          'happy',
          'sad',
          'surprised',
          'smile',
        ]
        // ぶんまわし
        for (let i = 0; i < timeline.length; i++) {
          // 変数定義
          const box = timeline[i]
          let has = []
          const queryEmotion = emotionkeys[this.sceneQuery.emotion.value]
          // 人物指定の有無
          if (this.selectedGroups.length) {
            // 登場人物指定がある場合のフィルタリング
            // 集めた検索結果から箱を判定
            const frameHeight = 360
            const min = this.sceneQuery.cast.score[0]
            const max = this.sceneQuery.cast.score[1]
            const hascast: any = this.selectedGroups.map((group: any) => {
              return group.sceneInfo.some((scene: any) => {
                const sceneStartTime = new Date(scene.startTime).getTime()
                const sceneEndTime = new Date(scene.endTime).getTime()
                const faceSize = (scene.representativeFace.height / frameHeight) * 100
                // 表情指定の判定
                // boxに入っているemotionデータの座標とsceneの代表顔の座標を比較
                // 指定がある場合はemo_namesにいるかどうか、指定がない場合はtrue
                let hassmile: any = true
                let hasemotion: any = true
                // 笑顔判定
                if (this.setsmile && (this.sceneQuery.emotion.smile[0] > 0 || this.sceneQuery.emotion.smile[1] < 5)) {
                  // 変数
                  const value = [
                    [0, 60],
                    [60, 85],
                    [85, 95],
                    [95, 98],
                    [98, 99],
                    [99, 100],
                  ]
                  const representativeFace = scene.representativeFace
                  if (this.sceneQuery.emotion.smile[0] === 0) {
                    // 0〜60を拾う場合
                    // 含まないで判定
                    // overlap = スライダの上限値〜100でひっかけ、hassmileはその逆で返す
                    const min = value[this.sceneQuery.emotion.smile[1]][1]
                    const max = 100
                    const overlap = box.emotion_data['smile'].find((emo: any) => {
                      const emoTs = new Date(emo.ts).getTime()
                      // 値が範囲内かつposがかぶっている
                      return (min <= emo.Confidence && emo.Confidence <= max) &&
                        (sceneStartTime <= emoTs && emoTs <= sceneEndTime) &&
                        (emo.pos.x <= representativeFace.xPos + representativeFace.width && representativeFace.xPos <= emo.pos.x + emo.pos.width && emo.pos.y <= representativeFace.yPos + representativeFace.height && representativeFace.yPos <= emo.pos.y + emo.pos.height)
                    })
                    hassmile = overlap ? false : true
                  } else {
                    // 60以上の場合
                    // 含むで判定
                    // overlap = スライダ上限〜下限の範囲内でひっかけ、hassmileもそのまま返す
                    const min = value[this.sceneQuery.emotion.smile[0]][0]
                    const max = value[this.sceneQuery.emotion.smile[1]][1]
                    const overlap = box.emotion_data['smile'].find((emo: any) => {
                      const emoTs = new Date(emo.ts).getTime()
                      // 値が範囲内かつposがかぶっている
                      return (min <= emo.Confidence && emo.Confidence <= max) &&
                        (sceneStartTime <= emoTs && emoTs <= sceneEndTime) &&
                        (emo.pos.x <= representativeFace.xPos + representativeFace.width && representativeFace.xPos <= emo.pos.x + emo.pos.width && emo.pos.y <= representativeFace.yPos + representativeFace.height && representativeFace.yPos <= emo.pos.y + emo.pos.height)
                    })
                    hassmile = overlap ? true : false
                  }
                }
                // 表情判定
                if (queryEmotion) {
                  const min = this.sceneQuery.emotion.score[0]
                  const max = this.sceneQuery.emotion.score[1]
                  const representativeFace = scene.representativeFace
                  const overlap = box.emotion_data[queryEmotion].find((emo: any) => {
                    const emoTs = new Date(emo.ts).getTime()
                    // console.log(emo.pos.x, representativeFace.xPos, emo.pos.x + emo.pos.width)
                    return (min <= emo.Confidence && emo.Confidence <= max) &&
                      (sceneStartTime <= emoTs && emoTs <= sceneEndTime) &&
                      (emo.pos.x <= representativeFace.xPos + representativeFace.width && representativeFace.xPos <= emo.pos.x + emo.pos.width && emo.pos.y <= representativeFace.yPos + representativeFace.height && representativeFace.yPos <= emo.pos.y + emo.pos.height)
                  })
                  hasemotion = overlap ? true : false
                }
                return (
                  group.source_id === box.source_id &&
                  hassmile &&
                  hasemotion &&
                  box.start_time < sceneEndTime &&
                  sceneStartTime < box.end_time &&
                  min <= faceSize &&
                  faceSize <= max
                )
              })
            })
            // 全員が同じシーンフラグで結果を変える
            if (this.sceneQuery.cast.same) {
              // console.log(hascast)
              has.push(
                hascast.every((h: any) => h === true) // 全員がtrue
              )
            } else {
              has.push(
                hascast.some((h: any) => h === true) // 1人でもtrue
              )
            }
          } else {
            // 笑顔でフィルタリング
            if (this.setsmile && (this.sceneQuery.emotion.smile[0] > 0 || this.sceneQuery.emotion.smile[1] < 5)) {
              // 変数
              const value = [
                [0, 60],
                [60, 85],
                [85, 95],
                [95, 98],
                [98, 99],
                [99, 100],
              ]
              // const representativeFace = scene.representativeFace
              if (this.sceneQuery.emotion.smile[0] === 0) {
                // 0〜60を拾う場合
                // 含まないで判定
                // overlap = スライダの上限値〜100でひっかけ、hassmileはその逆で返す
                const min = value[this.sceneQuery.emotion.smile[1]][1]
                const max = 100
                const hassmile = box.emotion_data['smile'].some((emo: any) => {
                  return min <= emo.Confidence && emo.Confidence <= max
                })
                has.push(!hassmile)
              } else {
                // 60以上の場合
                // 含むで判定
                // overlap = スライダ上限〜下限の範囲内でひっかけ、hassmileもそのまま返す
                const min = value[this.sceneQuery.emotion.smile[0]][0]
                const max = value[this.sceneQuery.emotion.smile[1]][1]
                const hassmile = box.emotion_data['smile'].some((emo: any) => {
                  return min <= emo.Confidence && emo.Confidence <= max
                })
                has.push(hassmile)
              }
            }
            // 表情でフィルタリング
            if (this.sceneQuery.emotion.value !== 0) {
              const min = this.sceneQuery.emotion.score[0]
              const max = this.sceneQuery.emotion.score[1]
              has.push(
                box.emotion_data[queryEmotion].some((emo: any) => {
                  return min <= emo.Confidence && emo.Confidence <= max
                })
              )
            }
          }
          // 人物指定の有無に関わらないフィルタ
          // うなずきでフィルタリング
          if (this.sceneQuery.action.value === 1) {
            // console.log(this.sceneQuery.action.value)
            const min = this.sceneQuery.action.score[0]
            const max = this.sceneQuery.action.score[1]
            has.push(
              box.action_data.nod.some((nod: any) => {
                return min <= nod.confidence * 100 && nod.confidence * 100 <= max
              })
            )
          }
          // 音声でフィルタリング
          if (this.sceneQuery.speech.value) {
            const hastext: any = []
            const queries = this.sceneQuery.speech.value.split(/[\u{20}\u{3000}]/u)
            const paragraph = box.speech_data.map((speech: any) => speech.word.split('|')[0]).join('')
            queries.forEach((q: any) => {
              const query = `^(?=.*${q}).*$`
              const reg = new RegExp(query, 'i')
              hastext.push(
                // box.speech_data.some((text: any) => {
                //   return text.word.match(reg)
                // })
                paragraph.match(reg) ? true : false
              )
            })
            has.push(
              hastext.some((h: any) => h === true)
            )
          }

          // 結果のチェック
          box.disabled = has.length !== 0 && has.some((h: any) => h === false) // 1つでもfalseならdisable（AND検索）

        }
      }
      if (this.hasonly) {
        timeline = timeline.filter((tl: any) => !tl.disabled)
      }

      const et = Date.now()
      console.log('filterdTimeline', et - st)
      console.groupEnd()
      this.filterdTimeline = timeline
    },
    async handleScale() {
      console.log('update scale')
      // this.store.commit('setPending', true)
      // this.isUpdate = true
      await this.updateTimeline()
      // this.isUpdate = false
      // this.store.commit('setPending', false)
    },
    // sozaiFinderに戻る
    cancelResult() {
      this.store.commit('setResult', null)
      this.router.push('../')
    },
    async addCast(e: any) {
      // 日本語変換中はスルー
      if (!e.target.value || this.composing) { return }

      // 登場人物一覧に存在するかどうか
      // 別名検索なんかもケアする
      const cast = this.sceneQuery.cast.suggest.find((cast: any) => {
        const query = `^(?=.*${e.target.value}).*$`
        const reg = new RegExp(query, 'gi')
        return cast.name.match(reg)?.length || cast.watchlistComment.match(reg)?.length ? true : false
        // return cast.name === e.target.value
      })
      // 該当者なし
      if (!cast) { return }

      // console.log(cast)
      // ginfosを未取得なら取得する
      // awaitにする
      if (!cast.getginfos && Object.keys(cast.ginfos).length) {
        cast.getginfos = await Promise.all(
          Object.keys(cast.ginfos).map(async (key: any) => {
            return await this.axios
              .get(this.conf.ANALYZEBASE + cast.ginfos[key].ginfoUrl)
              .then((r: any) => {
                // console.log('ginfo:', r.data)
                if (r.data) {
                  r.data.emo_names = cast.ginfos[key].emo_names
                  this.ginfos[key].groupInfos.push(r.data)
                  return true
                } else {
                  return false
                }
              })
              .catch(() => {
                // console.log('ginfo get error')
                return false
              })
          })
        )
      }
      // 既に入力された人物との重複チェック
      const query = `^(?=.*${e.target.value}).*$`
      const reg = new RegExp(query, 'i')
      const exist = this.sceneQuery.cast.value.some((value: any) => value.name.match(reg))
      if (exist) {
        e.target.value = null
        return
      } else {
        this.sceneQuery.cast.value.push(cast)
        this.filterd()
        e.target.value = null
      }
    },
    async restoreCast(query: any) {
      const cast = this.sceneQuery.cast.suggest.find((cast: any) => cast.name === query.name)
      // // 該当者なし
      if (!cast) { return }
      // ginfosを未取得なら取得する
      // awaitにする
      if (!cast.getginfos && Object.keys(cast.ginfos).length) {
        cast.getginfos = await Promise.all(
          Object.keys(cast.ginfos).map(async (key: any) => {
            return await this.axios
              .get(this.conf.ANALYZEBASE + cast.ginfos[key].ginfoUrl)
              .then((r: any) => {
                // console.log('ginfo:', r.data)
                if (r.data) {
                  r.data.emo_names = cast.ginfos[key].emo_names
                  this.ginfos[key].groupInfos.push(r.data)
                  return true
                } else {
                  return false
                }
              })
              .catch(() => {
                // console.log('ginfo get error')
                return false
              })
          })
        )
      }
      return
    },
    removeCast(i: number) {
      console.log(i)
      this.sceneQuery.cast.value.splice(i, 1)
    },
    async getAction() {
      const keys: any = ['', 'nod_url']
      // this.store.commit('setPending', true)
      if (!this.sceneQuery.action.getactions[this.sceneQuery.action.value]) {
        const res = await Promise.all(this.sources.map(async (source: any) => {
            return await this.axios
              .get(source[keys[this.sceneQuery.action.value]])
              .then((r: any) => {
                if (r.data.results) {
                  this.actions[source.source_id][keys[this.sceneQuery.action.value].replace(/_url/g, '')] = r.data.results
                  return true
                } else {
                  return null
                }
              })
              .catch(() => {
                return null
              })
          })
        )
        this.sceneQuery.action.getactions[this.sceneQuery.action.value] = res.every((r: any) => r)
      }
      await this.updateTimeline()
      // this.store.commit('setPending', false)
    },
    async getEmotion() {
      // console.log(this.sceneQuery.emotion.value)
      const keys: any = [
        '',
        'angry',
        'calm',
        'confused',
        'disgusted',
        'fear',
        'happy',
        'sad',
        'surprised',
        'smile',
      ]
      // this.store.commit('setPending', true)
      if (!this.sceneQuery.emotion.getemotions[this.sceneQuery.emotion.value]) {

        await Promise.all(this.sources.map(async (source: any) => {
            return await this.axios
              .get(source['emo_' + keys[this.sceneQuery.emotion.value] + '_url'])
              .then((r: any) => {
                // console.log('ginfo:', r.data)
                if (r.data) {
                  this.emotions[source.source_id][keys[this.sceneQuery.emotion.value]] = r.data
                }
                return true
              })
              .catch(() => {
                // console.log('ginfo get error')
                return null
              })
          })
        )
        this.sceneQuery.emotion.getemotions[this.sceneQuery.emotion.value] = true
      }
      await this.updateTimeline()
      // this.store.commit('setPending', false)
    },
    // Player関連
    playVideo(scene: any) {
      const ct = (scene.start_time - scene.video_start_time) / 1000
      this.player.currentTime(ct)
      this.player.play()
    },
    playVideo2(ct: any) {
      this.player.currentTime(ct)
      this.player.play()
    },
    // タイムアップデート
    timeupdate() {
      if (this.isPlaying && this.player && this.selectedScenes) {
        // console.log(this.player.currentTime())
        const ct = this.player?.currentTime()
        this.filterdScenes = this.selectedScenes.filter((scene: any) => {
          return scene.startTime < ct && ct < scene.endTime
        })
        // console.log('アクティブなシーン', this.filterdScenes)
      }
    },
    // ピクチャーインピクチャー
    togglePinP() {
      if (this.player && this.selectedScenes) {
        this.$nextTick(() => {
          console.log(this.player.isInPictureInPicture())
        })
      }
    },
    // 枠のスタイル
    getFrameStyle(item: any) {
      const vw: any = 480 // プレビュー動画のwidth
      const fw: any = 640 // NEC解析画像のwidth
      const ratio = vw / fw
      return {
        left: `${item.xPos * ratio}px`,
        top: `${item.yPos * ratio}px`,
        width: `${item.width * ratio}px`,
        height: `${item.height * ratio}px`,
      }
    },
    // エラー文言
    getErrorString(errorCode: any) {
      let string = ''
      switch (parseInt(errorCode)) {
        case 0: {
          string = ''
          break
        }
        case 1: {
          string = '：エラー'
          break
        }
        case 2: {
          string = '：キャンセル'
          break
        }
      }
      return string
    },
    // 人物選択
    async clickFaceItem(group: any, type: string) {
      // 実はtypeは不要
      group.select = !group.select
      if (group.select === true) {
        // Loiterの場合、nameが空
        // 単一素材なのでgroupIdで比較してみる
        const cast = this.sceneQuery.cast.suggest.find((cast: any) => cast.groupId === group.groupId)

        // 該当者なし
        if (!cast) { return }

        // ginfosを未取得なら取得する
        if (!cast.getginfos && Object.keys(cast.ginfos).length) {
          cast.getginfos = await Promise.all(
            Object.keys(cast.ginfos).map(async (key: any) => {
              return await this.axios
                .get(this.conf.ANALYZEBASE + cast.ginfos[key].ginfoUrl)
                .then((r: any) => {
                  // console.log('ginfo:', r.data)
                  if (r.data) {
                    r.data.emo_names = cast.ginfos[key].emo_names
                    r.data.pwatch_id = cast.pwatch_id
                    r.data.pwatch_name = cast.pwatch_name
                    r.data.pwatch_other_name = cast.pwatch_other_name
                    this.ginfos[key].groupInfos.push(r.data)
                    return true
                  } else {
                    return false
                  }
                })
                .catch(() => {
                  // console.log('ginfo get error')
                  return false
                })
            })
          )
        }

        // 既に入力された人物との重複チェック
        const exist = this.sceneQuery.cast.value.some((cast: any) => cast.groupId === group.groupId)
        if (exist) {
          return
        } else {
          this.sceneQuery.cast.value.push(cast)
          this.filterd()
        }
      } else {
        const i = this.sceneQuery.cast.value.findIndex((v: any) => v.groupId === group.groupId)
        this.sceneQuery.cast.value.splice(i, 1)
        this.filterd()
      }
    },
    // 音声解析テキストを選択
    addSpeechText(text: string) {
      if (this.sceneQuery.speech.value) {
        // 既に入力された人物との重複チェック
        const query = `^(?=.*${text}).*$`
        const reg = new RegExp(query, 'i')
        const exist = this.sceneQuery.speech.value.match(reg)
        if (!exist) {
          this.sceneQuery.speech.value += ` ${text}`
        }
      } else {
        this.sceneQuery.speech.value = `${text}`
      }
      this.filterd()
    },
    // コンテキストメニューを開く
    openContextMenu(e: any, item: any, type: any) {
      this.context = {
        type: type,
        item: item,
        position: { x: e.clientX, y: e.clientY }
      }
      window.addEventListener('click', this.closeContextMenu)
      document.oncontextmenu = function () { return false }
      // console.log(this.context)
    },
    closeContextMenu(e: any) {
      e.preventDefault()
      this.context = null
      window.removeEventListener('click', this.closeContextMenu)
      document.oncontextmenu = function () { return true }
      // console.log(this.context)
    },
    contextStyle() {
      const x = this.context ? this.context.position.x : 0
      const y = this.context ? this.context.position.y : 0
      const wb = window.innerHeight
      // console.log(x, y)
      return { left: `${x}px`, top: `${wb - y > 50 ? y - 10 : wb - 60}px` }
    },
    // ウォッチリスト追加
    openAdd(type: string) {
      // console.log(this.context)
      this.dialog = {
        type: type,
        method: 'add',
        data: {
          filename: '',
          name: this.context.item.name,
          other_name: '',
          url: this.context.item.representativeFace.detectedImage,
          project_id: this.project.project_id,
        },
      }
      // this.add = {
      //   path: [this.project.name]
      // }
    },
    // 共有設定
    openShare() {
      this.share = this.project
    },
    // サムネイル開く
    openImage() {
      // const elem: any = document.getElementById('download')
      // if (elem) {
      //   // const elem = document.createElement('a')
      //   // document.body.appendChild(elem)
      //   // console.log(elem)
      //   // elem.download = `${this.context.item.source_name}_${this.context.item.tc}.jpg`
      //   // elem.href = this.context.item.img_src
      //   // elem.click()


      //   // elem.remove()
      //   // const url: any = URL.createObjectURL(this.context.item.img_src)
      //   // console.log(url)
      //   // const a = document.createElement('a')
      //   // document.body.appendChild(a)
      //   // a.download = 'foo.jpg'
      //   // a.href = url
      //   // a.click()
      //   // a.remove()
      //   // URL.revokeObjectURL(url)
      // }
      window.open(this.context.item.img_hd_src, '_blank')
    },
    // タイムラインを選択
    selectTimeline(item: any) {
      const box = this.filterdTimeline.find((box: any) => box.source_id === item.source_id && box.tc === item.tc)
      box.select = !box.select
    },
    selectAllTimeline(item: any) {
      const value = !this.allcheck
      this.filterdTimeline.forEach((box: any) => box.select = value)
    },
    ts2ct(timestamp: any) {
      const vs = new Date(this.sources[0].start_time).getTime()
      const ts = new Date(timestamp).getTime()
      return (ts - vs) / 1000
    },
    // JSON出力
    onDownload() {
      const checked = this.filterdTimeline.filter((box: any) => box.select && !box.disabled)
      if (checked.length) {
        this.download = checked
      } else {
        alert('出力したいシーンを選択してください')
      }
    },
    exportJson() {
      const checked = this.filterdTimeline
        .filter((box: any) => box.select && !box.disabled)
        .map((box: any) => {
          return {
            source_id: box.source_id,
            source_name: box.source_name,
            start_time: (box.start_time - box.video_start_time) / 1000,
            end_time: (box.end_time - box.video_start_time) / 1000,
          }
        })
      let outputs: any = {}
      if (this.sceneQuery.cast.value.length) {
        // 人物に紐づく出力
        this.selectedScenes.forEach((scene: any) => {
          // console.log('出演シーン', scene)
          // 登場シーンが表情のスコアを満たしているかどうか？
          if (this.sceneQuery.emotion.value) {
            const emotionkeys = [
              '',
              'angry',
              'calm',
              'confused',
              'disgusted',
              'fear',
              'happy',
              'sad',
              'surprised',
              'smile',
            ]
            const queryEmotion = emotionkeys[this.sceneQuery.emotion.value]
            const min = this.sceneQuery.emotion.score[0]
            const max = this.sceneQuery.emotion.score[1]
            const has = this.emotions[scene.source_id][queryEmotion].find((emo: any) => {
              return (min <= emo.Confidence && emo.Confidence <= max) &&
                (emo.pos.x <= scene.xPos + scene.width && scene.xPos <= emo.pos.x + emo.pos.width && emo.pos.y <= scene.yPos + scene.height && scene.yPos <= emo.pos.y + emo.pos.height)
            })
            // console.log(has)
            if (!has) {
              return
            }
          }
          const appears = checked.filter((box: any) => scene.startTime <= box.end_time && box.start_time <= scene.endTime )
          // console.log(appears)
          if (appears.length) {
            appears.forEach((appear: any) => {
              // 出力対象のシーンと、人物が登場しているシーンの素材IDを比較
              if (appear.source_id !== scene.source_id) {
                return
              }
              // outputsに該当する素材IDのオブジェクトがなければ追加
              if (!outputs[scene.source_id]) {
                outputs[scene.source_id] = {
                  name: scene.source_name,
                  data: [],
                }
              }
              // 該当する素材IDのオブジェクトに出力データを追加
              outputs[scene.source_id].data.push({
                Id: scene.pwatch_name || scene.name,
                Comment: '',
                InTime: this.mixins.sec2hhmmss(Math.max(scene.startTime, appear.start_time) * 1000, true),
                OutTime: this.mixins.sec2hhmmss(Math.min(scene.endTime, appear.end_time) * 1000, true),
              })
            })
          }
          // 出力するシーンが連続している場合は１つにまとめる
          if (outputs[scene.source_id] && outputs[scene.source_id].data.length) {
            outputs[scene.source_id].data.forEach((output: any, i: number) => {
              if (i > 0) {
                if (output.InTime === outputs[scene.source_id].data[i - 1].OutTime) {
                  outputs[scene.source_id].data[i - 1].OutTime = output.OutTime
                  outputs[scene.source_id].data.splice(i, 1)
                }
              }
            })
          }
        })
      } else {
        // 人物に紐づかない出力
        let Id: any = []
        if (this.sceneQuery.emotion.value) {
          const emotionkeys = [
            '',
            'angry',
            'calm',
            'confused',
            'disgusted',
            'fear',
            'happy',
            'sad',
            'surprised',
            'smile',
          ]
          const key = emotionkeys[this.sceneQuery.emotion.value]
          Id.push(key)
        }
        if (this.sceneQuery.action.value) {
          const actionkeys = ['', 'nod']
          const key = actionkeys[this.sceneQuery.action.value]
          Id.push(key)
        }
        if (this.sceneQuery.speech.value) {
          Id.push(this.sceneQuery.speech.value)
        }
        if (!Id.length) {
          Id.push('なし')
        }
        // checkedからoutputsを生成
        checked.forEach((box: any) => {
          if (outputs[box.source_id]) {
            outputs[box.source_id].data.push({
              Id: '条件検索：' + Id.join('&'),
              Comment: '',
              InTime: this.mixins.sec2hhmmss(box.start_time * 1000, true),
              OutTime: this.mixins.sec2hhmmss(box.end_time * 1000, true),
            })
          } else {
            outputs[box.source_id] = {
              name: box.source_name,
              data: [
                {
                  Id: '条件検索：' + Id.join('&'),
                  Comment: '',
                  InTime: this.mixins.sec2hhmmss(box.start_time * 1000, true),
                  OutTime: this.mixins.sec2hhmmss(box.end_time * 1000, true),
                },
              ],
            }
          }
        })
      }
      this.mixins.getKaotanJSON(outputs)
      this.closeDialog()
    },
    closeDialog() {
      this.download = null
      this.dialog = {
        type: null,
        method: null,
        data: null,
      }
      this.share = null
    },
    // 再解析
    async onResearch() {
      const res = await this.mixins.research(this.sources)
      if (res) {
        alert('再解析を受け付けました')
        location.reload()
      } else {
        console.log('エラー')
      }
    },
    needResearch() {
      const lastPwatchUpdatedAtMsec = new Date(this.project.last_pwatch_updated_at).getTime() || 0
      const sourceUpdatedAtMsec = new Date(this.result.updated_at).getTime() || 0
      return Math.sign(lastPwatchUpdatedAtMsec - sourceUpdatedAtMsec) >= 0 ? true : false
    },
    async openLog() {
      const inputs = [this.result]
      const log = await this.mixins.getsourcelog(inputs)
      alert(log?.outputs[0].text)
    },
    getStep() {
      switch (this.result.str_status) {
        case 'アップロード中': {
          return '（1/4）'
        }
        case '解析中': {
          return '（2/4）'
        }
        case '解析完了': {
          return '（3/4）'
        }
        default: {
          return ''
        }
      }
    },
  },
})
